import React from "react";
import SettingsLayout from "./SettingsLayout";
import { Link } from "react-router-dom";
import TfaImage from "../../assets/images/two_fa.svg";
import QrCode from "../../assets/images/tfa_qr_code.png";
import Input from "../../components/styledComponents/Input/Input";
import CopyCheckmarkButton from "../../components/styledComponents/Buttons/CopyCheckmarkButton";
const SettingsSecurityTfa = () => {
  const outline = "outline";
    return (
    <div>
      
          <div className="flex">
            <div className="pt-4 max-w-[60%] flex flex-col items-center justify-center">
              <div className="mt-4 mb-8 max-w-[40%]">
                <img src={TfaImage} alt="" />
              </div>
              <div className="mb-4 text-center">
                <h1 className="text-[#002C3A] font-semibold text-3xl">
                TWO-FACTOR AUTHENTICATION
                </h1>
              </div>
              <div className="text-center px-6 mb-6">
                <p className="text-[#959BC9] font-semibold text-lg">
                2FA is an extra security layer for your account that requires you to enter a passcode to log in or withdraw funds. To enable it, please install Google Authenticator or Authy on your smartphone and scan or copy the QR code on the following page. Don't forget to save your recovery key in the process. You will then be asked to confirm the 6-digit code from the authentication app.
                </p>
              </div>
              <div className="mb-4 w-[60%]">
                <Input placeholder="Your 2FA passcode" className="w-full text-center" />
              </div>
              <div className="  mb-4 ">
                <CopyCheckmarkButton logo='check'  >
                  Enable
                  
                </CopyCheckmarkButton>

              </div>
            </div>
            <div className="h-full w-[40%] py-4">
              <div className="border-[1px]  border-[#C4C4C4] rounded-[20px] h-full w-full">
                <div className="border-[#C4C4C4] border-b-[1px] p-6">
                  <h1 className="font-semibold text-2xl"> Enable 2FA</h1>
                </div>
                <div className="px-6 pt-4 pb-10 w-full flex flex-col items-center justify-center">
                  <div className="max-w-full mb-4 flex flex-col items-center justify-center">
                    <div className="p-4 bg-[#E5F9FF] rounded-[20px] max-w-[60%] mb-4">
                      <img src={QrCode} alt="" className="" />
                    </div>
                    <div className="text-center mb-8">
                      <p className="font-semibold text-lg font-[#002C3A] break-all ">
                        MM2XKUDOKM7CK4LMIJATUWZMHNBEKSTCORRWQ3D2JN2XGRRZKRJA
                      </p>
                    </div>
                  </div>
                  <div className="w-full mb-2 flex flex-col items-center justify-center">
                    <CopyCheckmarkButton logo='copy' variant={outline}>
                      Copy Security Key
                    </CopyCheckmarkButton>


     
                  </div>
                </div>
              </div>
            </div></div>
          {/* </div>
        </div>
      </SettingsLayout> */}
    </div>
  );
};

export default SettingsSecurityTfa;
