import React from 'react'
import Layout from "../../components/layout/Layout"
import Welcome from './welcome/Welcome';
import YLDToken from './yldToken/YLDToken';
import Progress from './progress/Progress';
import Overview from './overview/Overview';

function Dashboard() {
    return (
       <Layout>
            <div className="flex gap-2 mx-10 max-h-full">
                <div className='bg-white px-6 py-2 rounded-2xl w-2/3 max-h-full'>
                    <p className="font-bold text-3xl py-4 color-[#002C3A]">Dashboard</p>
                    <div className="">
                        <Welcome/>
                    </div>
                </div>
                <div className="flex flex-col gap-2 w-1/3 max-h-full">
                    <div className="h-5/10">
                        <Overview/>
                    </div>
                    <div className="h-2/10">
                        <Progress/>
                    </div>
                    <div className="h-3/10">
                        <YLDToken/>
                    </div>
                </div>
            </div>
       </Layout>
    );
}
export default Dashboard
