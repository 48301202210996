import React from 'react'
import Button from '../../../components/styledComponents/Buttons/Button'

function Welcome() {
    return (
        <div className='bg-[#E5F9FF] rounded-2xl px-6 py-2'>
            <div className="py-2">
                <p className="text-2xl font-semibold">Welcome, UserName !</p>
            </div>
            <div>
                <p className="font-semibold color-[#1D288A] text-lg opacity-50">Please verify your identity to deposit funds, Make your first investment, and start earning with YIELD-YETI App.</p>
            </div>
            <div className="flex gap-4 pt-2">
                <div className="font-semibold text-lg">
                    <Button> Verify Your Identity </Button>
                </div>
                <div className="">
                    <Button variant="outline" background="no" text="cyan">  
                        Enable 2FA 
                    </Button>
                </div>
            </div>
        </div>
    )
}

export default Welcome
