import React from "react";
import BTC from "../../assets/images/btc.svg";
import YLD from "../../assets/images/yld.svg";
import ETH from "../../assets/images/eth.svg";
import USDT from "../../assets/images/usdt.svg";
import USDC from "../../assets/images/usdc.svg";
import RightArrow from "../../assets/images/right_arrow.svg";
const CoinTable = () => {
  const data = [
    {
      src: BTC,
      name: "BTC",
      value: 0.0,
      holdings: 0.0,
    },
    {
      src: YLD,
      name: "YLD",
      value: 0.0,
      holdings: 0.0,
    },
    {
      src: ETH,
      name: "ETH",
      value: 0.0,
      holdings: 0.0,
    },
    {
      src: USDT,
      name: "USDT",
      value: 0.0,
      holdings: 0,
    },
    {
      src: USDC,
      name: "USDC",
      value: 0.0,
      holdings: 0,
    },
  ];
  return (
    <div>
      <div className="border-[#C4C4C4] border-[1px] rounded-[20px] m-4">
        <div className="flex px-10 py-4 w-[95%]">
          <div className="font-semibold text-2xl font-[#002C3A] w-[33%] text-center">Assets</div>
          <div className="font-semibold text-2xl font-[#002C3A] w-[33%] text-center ml-2">Value</div>
          <div className="font-semibold text-2xl font-[#002C3A] w-[33%] text-center ml-2">Holding</div>
        </div>

        {data.map((val) => (
          <div className="flex border-t-[1px] border-[#C4C4C4]">
            <div className="flex px-10 py-8 w-[95%]">
              <div className="font-semibold text-2xl flex items-center w-[33%] justify-center">
                <img src={val.src} alt={val.name} className="mr-2" />
                {val.name}
              </div>
              <div className="font-semibold text-xl font-[#1D288A] opacity-50  w-[33%] text-center flex  items-center justify-center ml-2">
                $ {val.value}
              </div>
              <div className="font-semibold text-xl font-[#002C3A] w-[33%] text-center flex  items-center justify-center ml-2">{val.holdings}</div>
            </div>
            <div className="border-l-[1px] border-[#C4C4C4] flex  items-center justify-center  w-[5%] ">
              <img src={RightArrow} alt="Right Arrow" />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default CoinTable;
