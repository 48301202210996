import React from 'react'
import Button from '../../components/styledComponents/Buttons/Button'
import Profile from '../../assets/images/profile.svg'
import DepositInvest from './DepositInvest'

function Available() {
    return (
        <div className='w-full'>
            <p className='text-2xl font-semibold px-6 py-4 border-b border-[#C4C4C4] text-[#002C3A]'>Available</p>
            <div className='px-4 py-4 w-full'>
                <div className="border-solid border border-[#C4C4C4] rounded-2xl mx-4 my-4 py-2 px-4">
                    <div className="flex gap-2 py-1">
                        <img src={Profile} alt="Profile"/>
                        <p className="text-[#00C3FF] font-semibold text-lg">Account Value</p>
                    </div>
                    <div>
                        <p className="text-5xl font-black pb-2">$ 0.00</p>
                    </div>
                </div>
                <div className='relative py-2 w-full'>
                    <DepositInvest/>
                </div>
                <div className='py-2 flex justify-center w-full'>
                    <Button className="w-[50%]"> Continue</Button>
                </div>
            </div>
        </div>
    )
}

export default Available
