import React from 'react';

function Table() {

    const data = [
        {
            time: "07-12-2021, 18:16",
            type: "Deposit",
            asset: "USDT",
            amount: "5002",
            destination: "AbCdEfXyZ",
            txid: "012345678910",
            status: "Completed"
        },
        {
            time: "07-12-2021, 18:16",
            type: "Deposit",
            asset: "USDT",
            amount: "5002",
            destination: "AbCdEfXyZ",
            txid: "012345678910",
            status: "Completed"
        },
        {
            time: "07-12-2021, 18:16",
            type: "Deposit",
            asset: "USDT",
            amount: "5002",
            destination: "AbCdEfXyZ",
            txid: "012345678910",
            status: "Completed"
        },
        {
            time: "07-12-2021, 18:16",
            type: "Deposit",
            asset: "USDT",
            amount: "5002",
            destination: "AbCdEfXyZ",
            txid: "012345678910",
            status: "Completed"
        },
        {
            time: "07-12-2021, 18:16",
            type: "Deposit",
            asset: "USDT",
            amount: "5002",
            destination: "AbCdEfXyZ",
            txid: "012345678910",
            status: "Completed"
        }
    ]

    return (

    <div>
      <div className="border-[#C4C4C4] border rounded-2xl">
        <div className="flex px-6 py-4 w-[95%] text-[#1D288A] opacity-50 font-semibold text-lg">
          <div className="w-[20%] ">Time</div>
          <div className="w-[12%] ml-2">Type</div>
          <div className="w-[10%] ml-2">Asset</div>
          <div className="w-[10%] ml-2">Amount</div>
          <div className="w-[18%] ml-2">Destination</div>
          <div className="w-[18%] ml-2">TxID</div>
          <div className="w-[12%] ml-2">Status</div>
        </div>

        {data.map((val) => (
          <div className="flex border-t-[1px] border-[#C4C4C4]">
            <div className="flex px-6 py-4 w-[95%] font-semibold text-lg text-[#002C3A]">
              <div className="w-[20%] ">
                {val.time}
              </div>
              <div className="w-[12%] ml-2">
                {val.type}
              </div>
              <div className="w-[10%] ml-2">
                  {val.asset}
              </div>
              <div className="w-[10%] ml-2">
                  {val.amount}
              </div>
              <div className="w-[18%] ml-2">
                  {val.destination.slice(0,5)}...{val.destination.slice(-5)}
              </div>
              <div className="w-[18%] ml-2">
                  {val.txid}
              </div>
              <div className="w-[12%] ml-2">
                  {val.status}
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
    )
}

export default Table
