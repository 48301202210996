import React from "react";
import HelpImage from "../../assets/images/help_support.svg";
import SettingsLayout from "./SettingsLayout";
import Button from "../../components/styledComponents/Buttons/Button";
import Input from "../../components/styledComponents/Input/Input";
import Textarea from "../../components/styledComponents/Input/Textarea";

const SettingsHelp = () => {
  return (
    <div>
      <SettingsLayout>
        <div className="pt-10 px-8 flex items-center">
          <div className="basis-1/2 px-2 max-w-full max-h-full">
            <div className="max-w-full  pl-4">
              <div className="w-full pb-2">
                <p className="font-semibold text-lg text-[#1D288A] opacity-50">
                  Email
                </p>
              </div>
              <div className="max-w-full pb-4 pr-2">
                <Input
                  placeholder="Enter Your Email"
                  type="email"
                  className="w-full"
                  bg="transparent"
                ></Input>
              </div>
              <div className="max-w-full pb-2 ">
                <p className="font-semibold text-lg text-[#1D288A] opacity-50">
                  Subject
                </p>
              </div>
              <div className="max-w-full pr-2 pb-4 ">
                <Input
                  placeholder="Enter Subject"
                  type="text"
                  className="w-full"
                  bg="transparent"
                ></Input>
              </div>
              <div className="max-w-full pb-2 ">
                <p className="font-semibold text-lg text-[#1D288A] opacity-50">
                  Description
                </p>
              </div>
              <div className="max-w-full pr-2 pb-4 ">
                <Textarea
                  placeholder="Enter the details of your request"
                  className="w-full"
                 
                ></Textarea>
              </div>
              <div className="max-w-sm pr-40 h-full">
                <Button className="w-full">Submit Request</Button>
              </div>
            </div>
          </div>
          <div className="basis-1/2 px-2 max-w-full flex justify-end items-center">
            <div>
              <img
                src={HelpImage}
                alt="Help and Support"
                className="max-w-[80%]"
              />
            </div>
          </div>
        </div>
      </SettingsLayout>
    </div>
  );
};

export default SettingsHelp;
