import React from "react";
import styled from "styled-components";
import google from "../../../assets/images/google.svg";

// Not Working as expected
// Do not know how to get the active state

//Some styles don't apply. The others that apply, break after the page is refreshed

const Button = styled.button`
  align-items: center;
  justify-content: center;
  border-radius: 15px;
  padding: 14px;
  background-color: #e5f9ff;
  display: flex;
  column-gap: 13px;
  border: 1px solid #34cfff;
  /* width: 100%; */
`;

const Icon = styled.img`
  height: 25px;
  width: 25px;
`;

const Label = styled.p`
  font-size: 1rem;
  font-family: "Barlow", sans-serif;
  font-weight: 600;
  color: black;
`;
const ThirdPartyButton = (props) => {
  return (
    <Button className="w-full">
      <Icon src={google} />
      <Label>Continue with Google</Label>
    </Button>
  );
};

export default ThirdPartyButton;
