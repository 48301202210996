import Dashboard from "../pages/dashboard/Dashboard"

function Demo() {
  return (
    <>
      <Dashboard/>
    </>
  );
}

export default Demo;

