import React from "react";
import Button from "../../components/styledComponents/Buttons/Button";
import Logo from "../../assets/images/verify_email.svg";

const EmailVerify = () => {
  return (
    <div className="bg-lightgrey h-screen pt-10 flex flex-col items-center justify-center max-w-screen px-2">
      <div className="max-w-[75%] flex-col items-center justify-center flex">
        <div className="mb-10 max-w-[75%]">
          <img src={Logo} alt="Email verification" className="w-full" />
        </div>
        <div className="max-w-full flex flex-col items-center ">
          {/* <div class="mb-10 flex flex-col justify-center items-center"> */}
          <div className="mb-4 max-w-full text-center">
            <h2 className="font-sans text-5xl font-extrabold text-center ">
              Verify Your Email Address
            </h2>
          </div>
          <div className="max-w-[75%] flex flex-col items-center ">
            <div className="mb-8 text-center max-w-[90%]">
              <h3 className="font-sans text-xl font-semibold text-center  ">
                Please click the confirmation link in the email we just sent
                you.
              </h3>
            </div>
          </div>
          <div className="w-[75%] ">
            <div className="mb-2 w-full">
              <Button className="w-full">Check Email Now</Button>
            </div>
          </div>
          <div className="mb-4 text-center">
            <a
              className="font-sans text-link font-semibold text-center"
              href=""
            >
              Resend Email
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EmailVerify;
