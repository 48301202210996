import React from 'react'
import ResetPasswordImage from "../../assets/images/loginImage.svg";
import Logo from "../../assets/images/logo.svg"
import Input from "../../components/styledComponents/Input/Input.js";
import Button from "../../components/styledComponents/Buttons/Button";
import { Link } from 'react-router-dom';

function ResetPassword() {
    return (
        <div className="grid grid-cols-9 divide-x place-content-center">
            
            <div className="col-span-6 bg-[#F4F7FA] flex justify-center items-center">
                <img src={ResetPasswordImage} alt="ResetPassword" className="onboarding-bg" />
            </div>

            <div className="col-span-3 border-none px-12 py-10">
                <div className="py-2">
                    <img src={Logo} />
                </div>
                <div className="font-semibold text-4xl mt-6 py-4">
                    Reset Password
                </div>  
                <div className="font-semibold text-lg">
                    Create New Password for your account
                </div>
                <div className="pt-8">
                    <Input placeholder='New Password' type='password' className="w-full" />
                    <Input placeholder='Re-enter New Password' type='password' className="w-full" />

                </div>
                <div className="w-full">
                    <Link to="/password-reset-success" className="w-full"><Button className="w-full" > Reset Password </Button></Link>
                </div>
            </div>
        </div>
    )
}

export default ResetPassword

