import React from "react";
import SettingsLayout from "./SettingsLayout";
import { Link } from "react-router-dom";

const SettingsLegalPrivacy = () => {
  return (
    <div>
      {/* <SettingsLayout> */}
        {/* <div className="flex h-full ">
          <div className="basis-1/5 max-w-full max-h-full border-r-[#C4C4C4] border-r-[1px]">
            <div className="border-[1px] border-white  mb-2">
              <Link to="/settings/legal/terms-of-use">
                <div className="p-6">
                  <p className="text-[#1D288A] font-semibold opacity-50 text-xl">
                    Terms of Use
                  </p>
                </div>
              </Link>
            </div>
            <div className="bg-[#E5F9FF]/60 border-[#74D5F3] border-opacity-60 border-[1px]">
              <Link to="/settings/legal/privacy-policy">
                <div className="p-6">
                  <p className="text-[#1D288A]  font-semibold text-xl">
                    Privacy Policy
                  </p>
                </div>
              </Link>
            </div>
          </div>

          <div className="basis-4/5 py-10 px-10 max-w-full h-full"> */}
            <p className="opacity-50 text-[#1D288A] font-semibold text-lg">
              Lorem ipsum dolor, sit amet consectetur adipisicing elit. Iusto,
              hic voluptatum in quisquam amet architecto omnis! Architecto
              minima dolorum blanditiis consequatur culpa porro beatae dicta.
              Iste nihil laudantium eius autem ratione voluptates odio velit
              nisi! Alias vero, reiciendis nulla consectetur incidunt cum aut
              tempora ut optio ab adipisci blanditiis asperiores ad voluptatum
              molestias! Earum recusandae dicta quae, id iusto nobis beatae
              laborum fuga molestiae autem nostrum veniam porro doloribus alias
              ratione dolorum facere tempore magni repudiandae consequatur
              voluptatem exercitationem quam aliquid inventore. Corrupti atque
              aliquam ad tempore. Harum voluptatum tempora iure reprehenderit
              quas quae animi non maiores doloribus esse ipsa sequi impedit
              temporibus nesciunt suscipit eum vel quis nihil nostrum cumque,
              rerum a laudantium laboriosam voluptas! Voluptatum eius sit
              debitis delectus placeat, et, perferendis illum tempora ipsa unde
              accusantium illo explicabo libero quis, quod enim! Consequuntur
              ullam exercitationem perferendis! Voluptas, id ullam nesciunt
              distinctio fugiat magnam repellendus amet illo placeat, inventore
              sapiente nisi velit cum omnis modi esse quae doloremque magni
              blanditiis quo aperiam reprehenderit asperiores vero. Quae,
              voluptas? Labore nostrum ipsam tempore aspernatur. Nam asperiores,
              accusantium a ullam harum aliquid. Eveniet corporis vitae nam!
              Nesciunt nobis corporis, eaque voluptas voluptate architecto eum a
              dicta possimus vitae delectus illum inventore non, sed quod est
              necessitatibus provident fugit voluptatum neque maxime velit
              commodi. Autem, doloribus soluta. Eveniet possimus, quod illum
              expedita sint dolor voluptatem nostrum iusto autem corporis eum
              asperiores dolores ipsum natus aspernatur officiis in nesciunt
              repudiandae dolorem fuga. Aut numquam necessitatibus fugiat
              temporibus nostrum quod officia exercitationem rem illo sunt ab
              delectus, minima amet ex, assumenda architecto. Facilis, a. Ipsum
              cum, praesentium nisi suscipit modi id amet quo adipisci.
              Consectetur totam eveniet recusandae culpa numquam, minima
              necessitatibus veniam velit quibusdam laborum corrupti, fugit
              voluptatibus distinctio dicta ea ex! Rerum quaerat ducimus itaque
              doloribus, exercitationem iusto dignissimos quo dolorem excepturi!
            </p>
          {/* </div>
        </div> */}
      {/* </SettingsLayout> */}
    </div>
  );
};

export default SettingsLegalPrivacy;
