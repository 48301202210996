import React from "react";
import Header from "../header/Header";
import Footer from "../footer/Footer";

function Layout(props) {
    return (
        <div className="h-screen flex flex-col justify-between">
            <Header/>
            <div className="flex-1 bg-[#F4F7FA] p-4">{props.children}</div>
            <Footer/>
        </div>
    )
}

export default Layout