import React from 'react'
import ForgotPasswordImage from "../../assets/images/forgot_password.svg";
import Logo from "../../assets/images/logo.svg"
import Input from "../../components/styledComponents/Input/Input.js";
import Button from "../../components/styledComponents/Buttons/Button";
import { Link } from 'react-router-dom';


function ForgetPassord() {
    return (
        <div className="grid grid-cols-9 divide-x place-content-center">
            
            <div className="col-span-6 bg-[#F4F7FA] flex justify-center items-center">
                <img src={ForgotPasswordImage} alt="ForgotPassword" className="onboarding-bg" />
            </div>

            <div className="col-span-3 border-none px-12 py-10">
                <div className="py-2">
                    <img src={Logo} />
                </div>
                <div className="font-semibold text-4xl py-4 mt-6">
                    Forgot Password
                </div>  
                <div className="font-semibold text-lg py-2">
                    It's sad that you arrived here. But Don't worry we will fix your problem.
                </div>
                <div className="pt-8">
                    <Input placeholder='Email address' type='text' className="w-full" />
                </div>
                <div className="w-full">
                    <Link to="/email-verify" className="w-full"><Button className="w-full"> Verify Email Address </Button></Link>
                </div>
            </div>
        </div>
    )
}

export default ForgetPassord

