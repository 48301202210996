import React from 'react'
import CheckButton from "../styledComponents/Buttons/CheckButton";

function Footer() {
    return (
        <div className="flex justify-between py-4 px-20 items-center w-full max-h-full">
            <div>
            <p className=' font-semibold text-[#1D288A] opacity-50'>© All Rights are Reserved @ YieldYeti.app</p></div>
            <div className="flex gap-6 items-center">
                <p className="text-center font-semibold text-[#1D288A] opacity-50">V&nbsp;1.0.0</p>
                <CheckButton logo="help" >Help</CheckButton>
            </div>
        </div>
    )
}

export default Footer
