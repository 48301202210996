import React from 'react'
import LoginImage from "../../assets/images/loginImage.svg";
import Logo from "../../assets/images/logo.svg"
import Input from "../../components/styledComponents/Input/Input.js";
import Button from "../../components/styledComponents/Buttons/Button";
import ThirdPartyButton from "../../components/styledComponents/Buttons/ThirdPartyButton";
import { Link } from 'react-router-dom';

function Register() {
    return (
        <div className="grid grid-cols-9 divide-x place-content-center">
            
            <div className="col-span-6 bg-[#F4F7FA] flex justify-center items-center">
                <img src={LoginImage} alt="Loginimage" className="onboarding-bg" />
            </div>

            <div className="col-span-3 border-none px-12 py-10">
                <div className="py-2">
                    <img src={Logo} />
                </div>
                <div className="font-semibold text-4xl my-4">
                    Create An Account
                </div>  
                <div className="font-semibold text-lg my-4">
                    Welcome to the yield yeti app. Create a new account by entering below details.
                </div>
                <div className="py-2">
                    <Input placeholder='Your Name' type='text' className="w-full" />
                    <Input placeholder='Email address' type='text' className="w-full" />
                    <Input placeholder='Password' type='password' className="w-full" />
                </div>
                <div className="font-semibold text-base my-4 text-[#C0CBCE]"> 
                <p> By continuing, you agree to YieldYeti App's 
                    <a
                        className="text-[#872BFD] underline"
                        href="https://yieldyeti.com/terms-and-conditions/"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        Terms of Use
                    </a>{" "}
                    and{" "}
                    <a
                        className="text-[#872BFD] underline"
                        href="https://yieldyeti.com/privacy-policy-2/"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        Privacy Policy
                    </a>
                </p>
                </div>
                <div className="py-2">
                    <Button className="w-full"> Sign Me Up </Button>
                </div>
                <div className="grid grid-cols-11 content-center pb-4">
                    <div className="col-span-5 mt-3"><hr/></div>
                    <p className="col-span-1 text-center font-semibold">OR</p>
                    <div className="col-span-5 mt-3"><hr/></div> 
                </div>                
                <div className="py-2"> 
                    <ThirdPartyButton />
                </div>
                <div className="font-semibold flex justify-center py-2">
                    <p>
                        Already have an account?{" "}
                        <Link className="text-[#872BFD]" to="/">
                            Login here
                        </Link>
                    </p>
                </div>
            </div>
        </div>
    )
}

export default Register
