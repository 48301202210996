import React from "react";
import Profile from "../../assets/images/no_profile_picture.svg";
import SettingsLayout from "./SettingsLayout";
import Button from "../../components/styledComponents/Buttons/Button";
import Input from "../../components/styledComponents/Input/Input";
const SettingsProfile = () => {
  return (
    <div>
      <SettingsLayout>
        <div className="pt-10 px-8 flex ">
          <div className="basis-1/4 px-2 max-w-full max-h-full">
            <div className=" bg-[#E5F9FF]/60 border-[1px]  border-[#74D5F3]/60 max-w-full h-full rounded-[20px]  flex flex-col justify-center items-center py-16">
              <div className="mb-8 max-w-full opacity-100">
                <img src={Profile} alt="No Profile" className="max-w-full" />
              </div>
              <div className="mb-6 max-w-full">
                <p className="font-semibold text-xl text-[#1D288A] opacity-50 text-center">
                  No profile picture found
                </p>
              </div>
              <div className="mb-8 w-full px-28">
                <Button
                  variant="outline"
                  background="yes"
                  text="cyan"
                  className="w-full"
                >
                  Upload
                </Button>
              </div>
            </div>
          </div>
          <div className="basis-1/2">
            <div className="w-full pl-12">
              <div className="w-full">
                <p className="font-semibold text-lg text-[#1D288A] opacity-50">
                  Name
                </p>
              </div>
              <div className="max-w-[60%]">
                <Input
                  placeholder="Enter Your Username"
                  type="text"
                  value="Awesome Yeti"
                  className="w-full"
                  bg="transparent"
                ></Input>
              </div>
              <div className="max-w-full">
                <p className="font-semibold text-lg text-[#1D288A] opacity-50">
                  Email Address
                </p>
              </div>
              <div className="max-w-[60%]">
                <Input
                  placeholder="Enter Your Email Address"
                  type="email"
                  value="yeti.awesome@mail.com"
                  className="w-full"
                  bg="transparent"
                ></Input>
              </div>
            </div>
          </div>
        </div>
      </SettingsLayout>
    </div>
  );
};

export default SettingsProfile;
