import React from "react";
import SettingsLayout from "./SettingsLayout";
import { Link } from "react-router-dom";

const SettingsLegalTerms = () => {
  return (
    <div>
      {/* <SettingsLayout>
        <div className="flex h-full ">
          <div className="basis-1/5 max-w-full max-h-full border-r-[#C4C4C4] border-r-[1px]">
            <div className="bg-[#E5F9FF]/60 border-[#74D5F3] border-opacity-60 border-[1px] mb-2">
              <Link to="/settings/legal/terms-of-use">
                <div className="p-6">
                  <p className="text-[#1D288A] font-semibold text-xl">
                    Terms of Use
                  </p>
                </div>
              </Link>
            </div>
            <div className="border-[1px] border-white">
              <Link to="/settings/legal/privacy-policy">
                <div className="p-6">
                  <p className="text-[#1D288A] opacity-50 font-semibold text-xl">
                    Privacy Policy
                  </p>
                </div>
              </Link>
            </div>
          </div>

          <div className="basis-4/5 py-10 px-10 max-w-full h-full"> */}
            <p className="opacity-50 text-[#1D288A] font-semibold text-lg">
              Lorem ipsum dolor sit, amet consectetur adipisicing elit.
              Excepturi modi quidem cumque nihil blanditiis odit aut, fugiat
              reiciendis, ullam, in itaque! Quisquam, officia! Ducimus eius et,
              optio delectus dolorum facere placeat doloremque? Vel adipisci
              commodi minima harum perferendis quis tenetur error officia
              consequuntur et voluptate provident est blanditiis, repellat at
              unde odit facilis dolore neque delectus veniam facere ex earum
              dolores. Ipsam animi porro ea laboriosam laudantium dolor minima
              inventore id facere deleniti? Voluptatem similique impedit
              excepturi. Aliquam alias, assumenda vero, repellat molestiae
              voluptatem, odit distinctio deleniti maiores omnis mollitia
              laudantium eaque officia totam eum doloremque hic sint commodi
              fugit natus quod. Pariatur alias architecto sed enim cupiditate,
              vitae explicabo officia tempore unde veniam veritatis delectus
              incidunt rerum animi error autem quos quod quas. Veritatis beatae
              quidem, molestias fuga animi ab a expedita iure dolore
              necessitatibus. Praesentium fugit repellendus ducimus deserunt
              asperiores amet omnis iste odit exercitationem perspiciatis
              quaerat fuga porro voluptas iusto, ipsam illo ratione, recusandae
              autem voluptatibus dignissimos corrupti. Praesentium nam facilis
              excepturi suscipit, iusto molestiae veritatis cumque quaerat
              debitis et recusandae non aspernatur quo adipisci perferendis
              quidem ex cum, culpa reiciendis consequuntur sed! Iure illum est
              quisquam aliquid amet ex minus ad culpa, aperiam consequatur
              maxime ab alias in! At possimus, odit vitae quibusdam rerum
              nesciunt esse minus optio natus id molestiae quos error nisi harum
              numquam incidunt obcaecati laborum omnis dolorum? Distinctio
              corrupti reiciendis accusantium exercitationem, delectus suscipit
              hic ratione ea repellendus voluptas quo praesentium deserunt
              dignissimos voluptatum, laboriosam expedita fugiat. Accusantium,
              natus in quis pariatur quisquam corrupti soluta magni est
              voluptatem doloribus eos ipsam tempora. Mollitia blanditiis atque
              autem minima dolore optio, assumenda repellat! Quis omnis natus
              impedit voluptates, dolor cupiditate inventore eos odit aliquam
              minima obcaecati, quam facere a quisquam maxime dolores distinctio
              esse amet earum aperiam laborum beatae fugiat animi asperiores?
              Voluptatibus, illum.
            </p>
          {/* </div>
        </div>
      </SettingsLayout> */}
    </div>
  );
};

export default SettingsLegalTerms;
