import styled from "styled-components";

{
  /*
      Usage:
     <Input placeholder='Enter the url' type='text/password/number'>
  </Input>

  <Input placeholder='Enter the url' type='text/password/number' bg="transparent">
  </Input>

  <Input placeholder='Enter the url' type='text/password/number' bg="transparent" opacity="1">
  </Input>
  */
}

const Input = styled.input.attrs((props) => ({
  type: props.type,
  placeholder: props.placeholder,
}))`
  // width: ${(props) => (props.width ? props.width : "auto")};
  height: ${(props) => (props.height ? props.height : "60px")};
  background: ${(props) => (props.bg ? props.bg : "#e5f9ff")};
  outline: ${(props) => props.bg === "transparent" ? "1px solid #C4C4C4" : "none"};
  border-radius: 20px;
  ::placeholder {
    color: ${(props) => (props.bg ? "#1d288a" : "#002c3a")};
    font-size: 18px;
    font-family: Barlow;
    font-style: normal;
    font-weight: 600;
    opacity: ${(props) => (props.opacity ? "1" : "0.5")};;
  }
  color: #002c3a;
  font-size: 18px;
  font-family: Barlow;
  font-style: normal;
  font-weight: 600;
  margin-bottom: 0.75rem;

  padding: 24px 24px;
  box-sizing: border-box;
  border: none;
  :focus {
    outline: 1px solid #34cfff;
    background: #fbfeff;
    color: black;
    opacity: 1;
  }
`;
export default Input;
