import React from "react";
import { NavLink, Link } from "react-router-dom";
const SettingsHeader = () => {
  return (
    <div>
      <div className="border-b-[1px] border-b-[#C4C4C4] pl-8 pt-4 pb-1 max-w-full">
        <div className=" mb-4 w-full h-full">
          <h1 className=" text-3xl font-semibold pb-1">Settings</h1>
          <div className="font-semibold text-lg opacity-50">
            <a href="/" className="font-semibold text-[#00C3FF] ">
              Dashboard {">"}
            </a>
            <span className="font-semibold "> Settings</span>
          </div>
        </div>
      </div>
      <div className="border-b-[1px] border-b-[#C4C4C4] py-6 pl-8">
        <NavLink
           to="/settings/profile/"
          
          className={({isActive}) => `
            text-lg text-center text-[#1D288A] ${!isActive && "opacity-50"} font-semibold px-4 pb-2 ${isActive && "border-b-[#000072] border-b-[1px]"}
          `}
          //Can also do this way:
          // className="text-xl text-center text-[#1D288A] font-semibold ml-4 px-4 pb-2 border-b-[#000072] border-b-[1px] opacity-100"
          // style={({ isActive }) => {
          //   return {
          //     opacity: !isActive && "0.5",
          //     border : !isActive && "none",
              
          //   };
          // }}
        >
          <span>Profile</span>
        </NavLink>
        <NavLink
           to="/settings/security/"
          
          className={({isActive}) => `
            text-lg text-center text-[#1D288A] ${!isActive && "opacity-50"} font-semibold ml-2 px-4 pb-2 ${isActive && "border-b-[#000072] border-b-[1px]"}
          `}
        >
          <span>Security</span>
        </NavLink>
        <NavLink
           to="/settings/legal/"
          
          className={({isActive}) => `
            text-lg text-center text-[#1D288A] ${!isActive && "opacity-50"} font-semibold ml-2 px-4 pb-2 ${isActive && "border-b-[#000072] border-b-[1px]"}
          `}
        >
          <span>Legal</span>
        </NavLink>
        
        <NavLink
          to="/settings/help-and-support/"
         
          className={({isActive}) =>`
            text-lg text-center text-[#1D288A] ${!isActive && "opacity-50"} font-semibold ml-2 px-4 pb-2 ${isActive && "border-b-[#000072] border-b-[1px]"}
          `}
        >
          <span>Help & Support</span>
        </NavLink>
      </div>
    </div>
  );
};

export default SettingsHeader;
