import React from "react";
import { useState } from "react";
import { NavLink } from "react-router-dom";
import NoRewards from "../../assets/images/no_rewards.svg";
const RewardsTransactions = () => {
  const [transactions, setTransactions] = useState(true);
  return (
    <div>
      <div className="bg-lightgrey max-w-screen h-screen ">
        <div className=" bg-white h-full max-w-full rounded-[20px] py-8 flex flex-col items-center justify-center">
          <div className="border-b-[1px] border-t-[1px] border-b-[#C4C4C4] border-t-[#C4C4C4]  py-6 pl-8 flex w-full">
            <div
              className={`
            text-xl text-center text-[#1D288A] cursor-pointer ${
              !transactions && "opacity-50"
            } font-semibold px-4 pb-2 ${
                transactions && "border-b-[#000072] border-b-[1px]"
              }
          `}
              onClick={() => setTransactions(true)}
            >
              <span>Transaction History</span>
            </div>
            <div
              className={`
            text-xl text-center text-[#1D288A] cursor-pointer ${
              transactions && "opacity-50"
            } font-semibold ml-2 px-4 pb-2 ${
                !transactions && "border-b-[#000072] border-b-[1px]"
              }
          `}
              onClick={() => setTransactions(false)}
            >
              <span>Reward History</span>
            </div>
          </div>
          <div className="flex h-full p-1">
            <img src={NoRewards} alt="No Rewards/Transactions" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default RewardsTransactions;
