import React from 'react'
import Wallet from "../../../assets/images/wallet.svg";
import Profile from "../../../assets/images/profile.svg";
import Portfolio from "../../../assets/images/portfolio.svg";


function Overview() {
    return (
        <div className="rounded-2xl bg-white py-4 px-6">
            <div className="py-2 px-2   ">
                <p className="font-semibold text-3xl color-[#002C3A]">Overview</p>
            </div>
            <div className="border-solid border border-[#74D5F3] rounded-2xl mx-2 my-2 py-2 px-4">
                <div className="flex gap-2 py-2">
                    <img src={Profile} alt="Profile"/>
                    <p className="text-[#00C3FF] font-semibold text-lg">Account Value</p>
                </div>
                <div>
                    <p className="text-5xl font-black pb-2">$ 0.00</p>
                </div>
            </div>
            <div className="border-solid border border-[#74D5F3] rounded-2xl my-2 mx-2 ">
                <div className="border-b border-[#74D5F3] py-2 px-4">
                    <div className="flex gap-2 py-2">
                        <img src={Portfolio} alt="Portfolio"/>
                        <p className="text-[#959BC9] font-semibold text-lg">Portfolio Value</p>
                    </div>
                    <div className="">
                        <p className="text-[#002C3A] font-semibold text-4xl pb-2">$ 0.00</p>
                    </div>
                </div>
                <div className="py-2 px-4">
                    <div className="flex gap-2 py-2 opacity-50">
                        <img src={Wallet} alt="Wallet"/>
                        <p className="text-[#1D288A] font-semibold text-lg">Wallet</p>
                    </div>
                    <div>
                        <p className="text-[#002C3A] font-semibold text-4xl pb-2">$ 0.00</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Overview
