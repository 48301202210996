import React , {useState} from 'react'
import Button from '../../components/styledComponents/Buttons/Button'
import Input from '../../components/styledComponents/Input/Input';
import YLD from '../../assets/images/yld.svg'
import BTC from '../../assets/images/btc.svg'
import ETH from '../../assets/images/eth.svg'
import USDC from '../../assets/images/usdc.svg'
import USDT from '../../assets/images/usdt.svg'
import DownArrow from '../../assets/images/down_arrow.svg'

function Invest() {

    const data = [
        {
          src: BTC,
          name: "BTC",
        },
        {
          src: YLD,
          name: "YLD",
        },
        {
          src: ETH,
          name: "ETH",
        },
        {
          src: USDT,
          name: "USDT",
        },
        {
          src: USDC,
          name: "USDC",
        },
      ];

    const [showList, setShowList] = useState(false);
  const handleClick = () => {
    setShowList(!showList);
  };

  window.onclick = function (event) {
    if (!event.target.matches(".dropbtn")) {
      setShowList(false);
    }
  };

    return (
        <div className='h-full w-full flex flex-col bg-white rounded-2xl border border-[#74D5F3]'>
            <p className="font-semibold text-2xl color-[#002C3A] px-6 py-4 border-b border-[#74D5F3]">Invest</p>
            <div className='flex flex-col justify-center items-center py-10'>
                
                    <div className="relative w-full flex flex-col items-center">
                        <div
                        onClick={handleClick}
                        className="dropbtn w-[80%] px-4 py-4 bg-[#E5F9FF] rounded-[20px] flex justify-between mb-4 cursor-pointer select-none"
                        >
                            <div className=" flex items-center justify-center font-semibold text-lg dropbtn">
                                <img src={YLD} alt="YLD" className="dropbtn mr-2 h-[70%]" />
                                YLD
                            </div>
                            <div className=" flex items-center justify-center">
                                {!showList && <img src={DownArrow} alt="Down arrow" className="dropbtn"/>}
                            </div>
                        </div>

                        <div className='w-[80%]'>
                        {showList && (
                        <div
                            className="dropdown w-[80%] absolute px-4 py-4 rounded-[20px] bg-[#E5F9FF] border-[1px] border-[#34CFFF] z-10 shadow-[0px_5px20px#C0EFFF] opacity-100"
                            tabindex="-1"
                        >
                            <div className="" role="none">
                            {data.map((val) => (
                                <div className="flex cursor-pointer divide-[#C4C4C4] divide-y divide-solid">
                                <div className="flex px-4 py-4 w-full">
                                    <div className="font-semibold text-lg flex items-center justify-center">
                                    <img src={val.src} alt={val.name} className="mr-2 h-[70%]" />
                                    <p>
                                    {val.name}</p>
                                    </div>
                                </div>
                                </div>
                            ))}
                            </div>
                        </div>
                        )}
                        </div>

                        <div className="flex flex-col items-center w-[80%] mb-4">
                        <Input placeholder="Amount" className="w-full" type="number" />
                        </div>
                    </div>

                <div className='py-2 flex justify-center w-full'>
                    <Button className="w-[50%]"> Continue</Button>
                </div>
            </div>
        </div>
    )
}

export default Invest
