import React from 'react'
import Layout from '../../components/layout/Layout'
import CoinTable from './CoinTable'
import WalletAvailable from './WalletAvailable'

function Wallet() {
    return (
        <Layout>
            <div className="flex gap-2 mx-10 max-h-full">
                <div className='w-2/3 max-h-full flex flex-col gap-3 bg-white px-6 py-2 rounded-2xl'>
                        <p className="font-bold text-3xl py-4 color-[#002C3A]">Wallet</p>
                        <div className="text-xl">
                            <a href="/dhrumildemo" className="font-semibold text-[#00C3FF] ">
                            Dashboard {" > "}
                            </a>
                            <span className="font-semibold text-[#000072]">Wallet</span>
                        </div>
                        <div className="">
                            <CoinTable/>
                        </div>
                </div>
                <div className="flex flex-col gap-3 w-1/3 h-max bg-white rounded-2xl">
                   <WalletAvailable/>
                </div>
            </div>
        </Layout>
    )
}

export default Wallet
