import React from "react";
import { useState } from "react";
import Button from "../../components/styledComponents/Buttons/Button";
import Input from "../../components/styledComponents/Input/Input";

import BTC from "../../assets/images/btc.svg";
import YLD from "../../assets/images/yld.svg";
import ETH from "../../assets/images/eth.svg";
import USDT from "../../assets/images/usdt.svg";
import USDC from "../../assets/images/usdc.svg";
import DownArrow from "../../assets/images/down_arrow.svg";
const DepositWithdraw = () => {
  const [deposit, setDeposit] = useState(true);
  const [showList, setShowList] = useState(false);
  const handleClick = () => {
    setShowList(!showList);
};
window.onclick = function (event) {
  if (!event.target.matches(".dropbtn")) {
    setShowList(false);
  }
};
const data = [
  {
    src: BTC,
    name: "BTC",
    value: 0.0,
    holdings: 0.0,
  },
  {
    src: YLD,
    name: "YLD",
    value: 0.0,
    holdings: 0.0,
  },
  {
    src: ETH,
    name: "ETH",
    value: 0.0,
    holdings: 0.0,
  },
  {
    src: USDT,
    name: "USDT",
    value: 0.0,
    holdings: 0,
  },
  {
    src: USDC,
    name: "USDC",
    value: 0.0,
    holdings: 0,
  },
];

  return (
    <div>
      <div className="p-4 flex flex-col items-center">
        <div className=" w-full border-[1px] border-[#34CFFF] rounded-[20px] bg-[#E5F9FF] flex overflow-hidden mb-4">
          <div
            className={`p-4 rounded-r-[20px] w-[50%] text-center font-semibold cursor-pointer ${
              deposit && "bg-[#34CFFF] "
            }`}
            onClick={() => setDeposit(true)}
          >
            <p>Deposit</p>
          </div>
          <div
            className={`p-4 rounded-l-[20px] w-[50%] text-center font-semibold cursor-pointer ${
              !deposit && "bg-[#34CFFF] "
            }`}
            onClick={() => setDeposit(false)}
          >
            <p>Withdraw</p>
          </div>
        </div>
        

        { deposit? (
        <div className="w-full flex flex-col items-center">
          <div onClick={handleClick} className="dropbtn w-[80%] px-4 py-4 bg-[#E5F9FF] rounded-[20px] flex justify-between mb-4 cursor-pointer select-none">
              <div className="flex items-center justify-center font-semibold text-lg dropbtn"><img src={YLD} alt="YLD" className="dropbtn mr-2 h-[70%]" />
                YLD</div>
                <div className=" flex items-center justify-center">
                {!showList && <img src={DownArrow} alt="Down arrow" className="dropbtn"/>}
              </div>
            </div>

            {showList && (
              
              <div
                className="dropdown origin-top-right absolute mt-20 w-[80%] px-4 py-4 rounded-[20px] bg-[#E5F9FF] border-[1px] border-[#34CFFF] z-10 shadow-[0px_5px_20px_#C0EFFF] opacity-100"
                role="menu"
                aria-orientation="vertical"
                aria-labelledby="menu-button"
                tabindex="-1"
              >
                <div className="" role="none">
                  {data.map((val) => (
                    <div className="flex cursor-pointer divide-[#C4C4C4] divide-y divide-solid">
                      <div className="flex px-4 py-4 w-full">
                        <div className="font-semibold text-lg flex items-center justify-center">
                          <img src={val.src} alt={val.name} className="mr-2 h-[70%]" />
                          <p>
                          {val.name}</p>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            )}


            <div className="flex flex-col items-center w-[80%] mb-4">
              <Input placeholder="Amount" className="w-full" type="number" />
            </div>
          </div>
        ) : 
        (
        <div className="w-full flex flex-col items-center">
          <div onClick={handleClick}
              className="dropbtn w-[80%] px-4 py-4 bg-[#E5F9FF] rounded-[20px] flex justify-between mb-4 cursor-pointer select-none">
              <div className=" flex items-center justify-center font-semibold text-lg dropbtn">
                <img src={YLD} alt="YLD" className="dropbtn mr-2 h-[70%]" />
                YLD
              </div>
              <div className=" flex items-center justify-center">
                {!showList && <img src={DownArrow} alt="Down arrow" className="dropbtn"/>}
              </div>
            </div>

            {showList && (
              
              <div
                className="dropdown origin-top-right absolute mt-20 w-[80%] px-4 py-4 rounded-[20px] bg-[#E5F9FF] border-[1px] border-[#34CFFF] z-10 shadow-[0px_5px_20px_#C0EFFF] opacity-100"
                role="menu"
                aria-orientation="vertical"
                aria-labelledby="menu-button"
                tabindex="-1"
              >
                <div className="" role="none">
                  {data.map((val) => (
                    <div className="flex cursor-pointer divide-[#C4C4C4] divide-y divide-solid">
                      <div className="flex px-4 py-4 w-full">
                        <div className="font-semibold text-lg flex items-center justify-center">
                          <img src={val.src} alt={val.name} className="mr-2 h-[70%]" />
                          <p>
                          {val.name}</p>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            )}


        <div  className="flex flex-col items-center w-[80%] mb-4">
            <Input placeholder="Amount"  className="w-full" type="number" /></div>
            <div className='mb-4 w-[80%]'>
                    <p className='text-right text-[#1D288A] font-semibold text-md'>Withdrawal Fee: 20 YLD</p>
                </div>
                <div  className="flex flex-col items-center w-[80%] mb-4"> 
                <Input placeholder="Recipient's address" className="w-full" /></div>
                <div className='flex justify-center py-2 w-[50%]'>
                    <Button className="w-full"> Continue</Button>
                </div>
        </div> )}

      </div>
    </div>
  );
};

export default DepositWithdraw;
