import React from 'react'
import Layout from '../../components/layout/Layout'
import Table from './table/Table'
import SwapYLD from './swapyld/SwapYLD'

function Swap() {
    return (
        <Layout>
            <div className="flex gap-2 mx-10 max-h-full">
                <div className='bg-white px-6 py-2 rounded-2xl w-2/3 max-h-full'>
                    <p className="font-bold text-3xl py-4 color-[#002C3A]">Swap</p>
                    <div className="text-xl">
                        <a href="/dhrumildemo" className="font-semibold text-[#00C3FF] ">
                        Dashboard {" > "}
                        </a>
                        <span className="font-semibold text-[#000072]">Swap</span>
                    </div>
                    <p className="font-semibold text-2xl pt-8 color-[#002C3A]">History</p>
                    <div className="py-2">
                        <Table/>
                    </div>
                </div>
                <div className="bg-white rounded-2xl w-1/3 h-max">
                    <SwapYLD/>
                </div>
            </div>
        </Layout>
    )
}

export default Swap
