import React from "react";
import Button from "../../components/styledComponents/Buttons/Button";
import Logo from "../../assets/images/password_reset_succesful.svg";
import { Link } from "react-router-dom";
const PasswordResetSuccess = () => {
  return (
    <div className="bg-lightgrey h-screen pt-16 flex flex-col items-center max-w-screen px-2">
      <div className="w-[75%] flex-col items-center flex">
        <div className="mb-10 max-w-full">
          <img src={Logo} alt="Password reset success" className="w-full" />
        </div>
        <div className="max-w-full flex flex-col items-center ">
          {/* <div class="mb-10 flex flex-col justify-center items-center"> */}
          <div className="mb-4 max-w-full text-center">
            <h2 className="font-sans text-5xl font-extrabold text-center">
              Password Reset Successful
            </h2>
          </div>
          <div className="max-w-[75%] flex flex-col items-center ">
            <div className="mb-8 text-center max-w-[90%]">
              <h3 className="font-sans text-xl font-semibold text-center">
                Your Account password has been successfully reset, Login with
                your new password
              </h3>
            </div>
          </div>
          <div className="w-[50%] ">
            <div className=" mb-2">
              <Link className="w-full" to="/">
                <Button className="w-full">Login Now</Button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PasswordResetSuccess;
