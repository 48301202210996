import { BrowserRouter, Routes, Route } from "react-router-dom";
import Demo from "../pages/Demo";
import DhyeyDemo from "../pages/DhyeyDemo";
import DhrumilDemo from "../pages/DhrumilDemo";
import Login from "../pages/auth/Login";
import Register from "../pages/auth/Register";
import EmailVerify from "../pages/auth/EmailVerify";
import PasswordResetSuccess from "../pages/auth/PasswordResetSuccess";
import EmailVerifySuccess from "../pages/auth/EmailVerifySuccess";
import ForgotPassword from "../pages/auth/ForgotPassword";
import ResetPassword from "../pages/auth/ResetPassword";
import SettingsHome from "../pages/settings/SettingsHome";

import SettingsProfile from "../pages/settings/SettingsProfile";
import SettingsHelp from "../pages/settings/SettingsHelp";
import SettingsLegalHome from "../pages/settings/SettingsLegalHome";
import SettingsSecurityHome from "../pages/settings/SettingsSecurityHome";

import Dashboard from "../pages/dashboard/Dashboard";
import Portfolio from "../pages/portfolio/Portfolio";
import Fiat from "../pages/fiat/Fiat";
import Swap from "../pages/swap/Swap";
import Asset from "../pages/wallet/Asset";
import Wallet from "../pages/wallet/Wallet";


function PublicRoute() {
  return (
    <div>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/reset-password" element={<ResetPassword />} />
          <Route path="/email-verify" element={<EmailVerify />} />
          <Route
            path="/email-verify-success"
            element={<EmailVerifySuccess />}
          />
          <Route
            path="/password-reset-success"
            element={<PasswordResetSuccess />}
          />
          <Route path="/demo" element={<Demo />} />

          <Route path="/dhrumildemo" element={<DhrumilDemo />} />
          <Route path="/dhyeydemo" element={<DhyeyDemo />} />

          <Route path="/settings" element={<SettingsHome />} />
          {/* <Route path="/settings/*" element={<SettingsProfile/>} /> */}
          <Route path="/settings/profile" element={<SettingsProfile />} />
          <Route path="/settings/help-and-support" element={<SettingsHelp />} />
          <Route  
            path="/settings/legal"
            element={<SettingsLegalHome />}
          />
          
          <Route path="/settings/security" element ={<SettingsSecurityHome/>} />
        

          <Route path="/dashboard" element={<Dashboard />}/>
          <Route path="/portfolio" element={<Portfolio />} />
          <Route path="/fiat" element={<Fiat />} />
          <Route path="/swap" element={<Swap />} />
          <Route path="/asset" element={<Asset />} />
          <Route path="/wallet" element={<Wallet />} />

        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default PublicRoute;
