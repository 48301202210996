import React from 'react'
import BTC from '../../assets/images/btc.svg'
import ETH from '../../assets/images/eth.svg'
import USDC from '../../assets/images/usdc.svg'
import YLD from '../../assets/images/yld.svg'

function Levels() {

    const data=[
        {
            src: BTC,
            percentage: '6%+2%',
            name: "BTC",
        },
        {
            src: ETH,
            percentage: '6%+2%',
            name: "ETH",
        },
        {
            src: USDC,
            percentage: '12%+2%',
            name: "USDC",
        },
        {
            src: YLD,
            percentage: '2%',
            name: "YLD",
        }
    ]

    return (
        <div className='border rounded-2xl border-[#959BC9] bg-[#FFFFFF]'>
            <div className='border rounded-2xl border-[#959BC9] bg-[#E5F9FF]'>
                <p className='text-lg font-semibold text-[#00C3FF] px-4 py-1'>Level 1</p>
            </div>
            <div className='flex flex-row gap-2 justify-around py-4'>
                {data.map((val, key) => {
                    return (
                        <div className='flex flex-col justify-center items-center px-2 py-2'>
                            <img src={val.src} alt="name" width="40px" height="40px"/>
                            <div className='text-lg font-semibold text-[#959BC9] text-center py-2'>
                                <p>{val.percentage}</p>
                                <p>{val.name}</p>
                            </div>
                        </div>
                    )
                })}
            </div>
        </div>
    )
}

export default Levels
