import React from "react";
import SettingsLayout from "./SettingsLayout";
const SettingsHome = () => {
  return (
    <div>
{/* <Layout> */}
      <SettingsLayout>
        {" "}
        <div className="text-4xl"></div>
      </SettingsLayout>
      {/* </Layout> */}
    </div>
  );
};

export default SettingsHome;
