import React from "react";
import SettingsLayout from "./SettingsLayout";
import { Link } from "react-router-dom";
import PasswordImage from "../../assets/images/password_security.svg";
import Input from "../../components/styledComponents/Input/Input";
import Button from "../../components/styledComponents/Buttons/Button";
const SettingsSecurityChangePassword = () => {
  return (
    <div>
      {/* <SettingsLayout>
        <div className="flex max-h-full">
          <div className="basis-1/5 max-h-full max-w-full  border-r-[#C4C4C4] border-r-[1px]">
            <div className="border-white border-[1px] mb-2">
              <Link to="/settings/security/two-factor-authentication">
                <div className="p-6">
                  <p className="text-[#1D288A] opacity-50 font-semibold text-xl">
                    Enable 2FA
                  </p>
                </div>
              </Link>
            </div>
            <div className=" bg-[#E5F9FF]/60 border-[#74D5F3] border-opacity-60 border-[1px] ">
              <Link to="/settings/security/change-password">
                <div className="p-6">
                  <p className="text-[#1D288A]  font-semibold text-xl">
                    Change Password
                  </p>
                </div>
              </Link>
            </div>
          </div>

          <div className="basis-4/5 py-2 pr-6 h-full flex"> */}
            <div className="flex">
            <div className="pt-4 max-w-[60%] flex flex-col items-center justify-center">
              <div className="mb-4 max-w-[40%]">
                <img src={PasswordImage} alt="" />
              </div>
              <div className="mb-4 text-center">
                <h1 className="text-[#002C3A] font-semibold text-3xl">
                  Password Security
                </h1>
              </div>
              <div className="text-center px-6">
                <p className="text-[#959BC9] font-semibold text-lg">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. In
                  imperdiet efficitur facilisis. Nunc imperdiet ultrices justo,
                  sit amet gravida dui gravida in. Nullam non ipsum vel augue
                  tempor vehicula ac sed tellus. Duis a risus rhoncus, rutrum
                  leo vitae, feugiat risus. Donec mollis, tortor ut malesuada
                  sollicitudin, purus augue euismod arcu, vitae elementum dolor
                  ligula vitae ipsum. Sed in magna nunc.
                </p>
              </div>
            </div>
            <div className="h-full w-[40%] py-4">
              <div className="border-[1px]  border-[#C4C4C4] rounded-[20px] h-full w-full">
                <div className="border-[#C4C4C4] border-b-[1px] p-6">
                  <h1 className="font-semibold text-2xl"> Change Password</h1>
                </div>
                <div className="px-6 pt-4 pb-10 w-full">
                  <div className="w-full">
                    <Input className="w-full" placeholder="Old Password" />
                  </div>
                  <div className="w-full">
                    <Input className="w-full" placeholder="New Password" />
                  </div>
                  <div className="w-full">
                    <Input
                      className="w-full"
                      placeholder="Re-enter New Password"
                    />
                  </div>
                  <div className="w-full mb-4">
                    <Button className="max-w-1/2"> Update Password</Button>
                  </div>
                </div>
              </div>
            </div>
            </div>
          {/* </div>
        </div>
      </SettingsLayout> */}
    </div>
  );
};

export default SettingsSecurityChangePassword;
