import React from 'react'
import Button from '../../../components/styledComponents/Buttons/Button'

function YLDToken() {
    return (
        <div className="rounded-2xl bg-white px-6 py-2">
            <div>
                <p className="text-2xl font-semibold">YLD TOKEN</p>
            </div>
            <div className="pt-2">
                <p className="text-[#959BC9] font-semibold text-lg">Learn more about YLD, its rewards and how to maximize them. </p>
            </div>
            <div className="pt-2">
                <Button variant="outline" background="yes"> <p className="text-[#00C3FF]">Learn more</p> </Button>
            </div>
        </div>
    )
}

export default YLDToken
