import React from 'react'
import Button from '../../components/styledComponents/Buttons/Button'
import YLD from '../../assets/images/yld.svg'
import DepositWithdraw from './DepositWithdraw'

function Available() {
    return (
        <div>
            <p className='text-2xl font-semibold px-6 py-4 border-b border-[#C4C4C4] text-[#002C3A]'>Available</p>
            <div className='flex flex-col px-2 py-4 w-full justify-center items-center'>
                <div className='border rounded-2xl px-4 py-4 border-[#74D5F3] self-start w-full'>
                    <div className='flex gap-1 items-center'>
                        <div>
                            <img src={YLD} alt="YLD" className='w-[80%]'/>
                        </div>
                        <p className='font-semibold text-lg text-[#00C3FF]'>YLD</p>
                    </div>
                    <p className='py-1 font-bold text-4xl text-[#002C3A]'>0</p>
                </div>
                <div className='relative w-full py-2'>
                    <DepositWithdraw/>
                </div>
                <div className='py-2 flex justify-center w-full'>
                    <Button className="w-[50%]"> Continue</Button>
                </div>
            </div>
        </div>
    )
}

export default Available
