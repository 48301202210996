import React, {useState} from 'react'
import YLD from '../../../assets/images/yld.svg'
import Input from '../../../components/styledComponents/Input/Input'
import Button from '../../../components/styledComponents/Buttons/Button'
import BTC from '../../../assets/images/btc.svg'
import ETH from '../../../assets/images/eth.svg'
import USDC from '../../../assets/images/usdc.svg'
import USDT from '../../../assets/images/usdt.svg'
import DownArrow from '../../../assets/images/down_arrow.svg'

function SwapYLD() {
    const data = [
        {
          src: BTC,
          name: "BTC",
        },
        {
          src: YLD,
          name: "YLD",
        },
        {
          src: ETH,
          name: "ETH",
        },
        {
          src: USDT,
          name: "USDT",
        },
        {
          src: USDC,
          name: "USDC",
        },
      ];

    const [showList, setShowList] = useState(false);
  const handleClick = () => {
    setShowList(!showList);
  };

  window.onclick = function (event) {
    if (!event.target.matches(".dropbtn")) {
      setShowList(false);
    }
  };

    return (
        <div className=''>
            <p className='text-xl font-semibold px-6 py-4 border-b border-[#C4C4C4] text-[#002C3A]'>SWAP YLD</p>
            <div className='px-4 py-4'>
                <div className="relative w-full flex flex-col items-center">
                        <div
                        onClick={handleClick}
                        className="dropbtn w-[80%] px-4 py-4 bg-[#E5F9FF] rounded-[20px] flex justify-between mb-4 cursor-pointer select-none"
                        >
                            <div className=" flex items-center justify-center font-semibold text-lg dropbtn">
                                <img src={YLD} alt="YLD" className="dropbtn mr-2 h-[70%]" />
                                YLD
                            </div>
                            <div className=" flex items-center justify-center">
                                {!showList && <img src={DownArrow} alt="Down arrow" className="dropbtn"/>}
                            </div>
                        </div>
                        
                        <div className='w-[80%]'> 
                        {showList && (

                        <div
                            className="dropdown absolute w-[80%] px-4 py-4 rounded-[20px] bg-[#E5F9FF] border-[1px] border-[#34CFFF] z-10 shadow-[0px_5px20px#C0EFFF] opacity-100"
                            tabindex="-1"
                        >
                            <div className="" role="none">
                            {data.map((val) => (
                                <div className="flex cursor-pointer divide-[#C4C4C4] divide-y divide-solid">
                                    <div className="flex px-4 py-4 w-full">
                                        <div className="font-semibold text-lg flex items-center justify-center">
                                            <img src={val.src} alt={val.name} className="mr-2 h-[70%]" />
                                            <p>{val.name}</p>
                                        </div>
                                    </div>
                                </div>
                            ))}
                            </div>
                        </div>
                        )}
                        </div>

                        <div className="flex flex-col items-center w-[80%] mb-4">
                        <Input placeholder="Amount" className="w-full" type="number" />
                        </div>
                </div>

                <div className='border rounded-2xl px-4 py-4 border-[#74D5F3] w-full'>
                    <p className='font-semibold text-lg text-[#00C3FF] pb-2'>You will receive</p>
                    <div className='flex gap-2 bg-[#E5F9FF] rounded-2xl px-6 py-3 items-center h-full'>
                        <div>
                            <img src={YLD} alt="YLD" className='w-[80%]'/>
                        </div>
                        <p className='font-semibold text-lg text-[#002C3A] opacity-50'>YLD 0.000</p>
                    </div>
                </div>
                <div className='flex justify-center py-6'>
                    <Button className="w-1/2"> Continue</Button>
                </div>
            </div>
        </div>
    )
}

export default SwapYLD
