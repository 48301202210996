import React from 'react'
import Layout from '../../components/layout/Layout'
import HistoryTable from './HistoryTable'

import Available from './AssetAvailable'


function Asset() {

    const data = [
        {
            time: "07-12-2021, 18:16",
            type: "Deposit",
            asset: "USDT",
            amount: "5002",
            destination: "AbCdEfXyZ",
            txid: "012345678910",
            status: "Completed"
        },
        {
            time: "07-12-2021, 18:16",
            type: "Deposit",
            asset: "USDT",
            amount: "5002",
            destination: "AbCdEfXyZ",
            txid: "012345678910",
            status: "Completed"
        },
        {
            time: "07-12-2021, 18:16",
            type: "Deposit",
            asset: "USDT",
            amount: "5002",
            destination: "AbCdEfXyZ",
            txid: "012345678910",
            status: "Completed"
        },
        {
            time: "07-12-2021, 18:16",
            type: "Deposit",
            asset: "USDT",
            amount: "5002",
            destination: "AbCdEfXyZ",
            txid: "012345678910",
            status: "Completed"
        },
        {
            time: "07-12-2021, 18:16",
            type: "Deposit",
            asset: "USDT",
            amount: "5002",
            destination: "AbCdEfXyZ",
            txid: "012345678910",
            status: "Completed"
        }
    ]


    return (
        <Layout>
            <div className="flex gap-2 mx-10 max-h-full">
                <div className='bg-white px-6 py-2 rounded-2xl w-2/3 max-h-full'>
                    <p className="font-bold text-3xl py-4 color-[#002C3A]">Swap</p>
                    <div className="text-xl">
                        <a href="/dhrumildemo" className="font-semibold text-[#00C3FF] ">
                        Dashboard {" > "}
                        </a>
                        <a href="/dhrumildemo" className="font-semibold text-[#00C3FF] ">
                        Wallet {" > "}
                        </a>
                        <span className="font-semibold text-[#000072]">Assets</span>
                    </div>
                    {data.length ? (
                        <div>
                            <p className="font-semibold text-2xl pt-8 color-[#002C3A]">History</p>
                            <div className="py-2">
                                <HistoryTable data={data}/>
                            </div>
                        </div>
                    ):
                    (
                        <div className="py-8 px-2">
                            <HistoryTable data={data}/>
                        </div>    
                    )}
                </div>
                <div className="bg-white rounded-2xl w-1/3 h-max">
                    <Available/>
                </div>
            </div>
        </Layout>
    )
}

export default Asset
