import React from 'react';
import NoTransactions from '../../assets/images/no_transactions.svg'

function Table(props) {
    return (
        <div className="w-full h-full rounded-2xl border border-[#C4C4C4]">
            {props.data.length ? (
                <div>
                    <div className="flex px-6 py-4 w-[95%] text-[#1D288A] opacity-50 font-semibold text-lg">
                      <div className="w-[20%] ">Time</div>
                      <div className="w-[12%] ml-2">Type</div>
                      <div className="w-[10%] ml-2">Asset</div>
                      <div className="w-[10%] ml-2">Amount</div>
                      <div className="w-[18%] ml-2">Destination</div>
                      <div className="w-[18%] ml-2">TxID</div>
                      <div className="w-[12%] ml-2">Status</div>
                    </div>
                    
                    {props.data.map((val,key) => (
                        <div className="flex border-t-[1px] border-[#C4C4C4]">
                          <div className="flex px-6 py-4 w-[95%] font-semibold text-lg text-[#002C3A]">
                            <div className="w-[20%] ">
                              {val.time}
                            </div>
                            <div className="w-[12%] ml-2">
                              {val.type}
                            </div>
                            <div className="w-[10%] ml-2">
                                {val.asset}
                            </div>
                            <div className="w-[10%] ml-2">
                                {val.amount}
                            </div>
                            <div className="w-[18%] ml-2">
                                {val.destination.slice(0,5)}...{val.destination.slice(-5)}
                            </div>
                            <div className="w-[18%] ml-2">
                                {val.txid}
                            </div>
                            <div className="w-[12%] ml-2">
                                {val.status}
                            </div>
                          </div>
                        </div>
                      ))}
                </div>
            ) :
            (!props.data.length && (
                <div className='h-full w-full flex flex-col'>
                    <p className="font-semibold text-2xl color-[#002C3A] px-8 py-4 border-b border-[#C4C4C4]">History</p>
                    <div className='flex flex-col justify-center items-center py-20'>
                        <img src={NoTransactions} alt="No Transaction"/>
                        <p className="flex justify-center content-center">-No Transactions-</p>
                    </div>
                </div>
            ))
            }    
        </div>
    )
}

export default Table
