import DepositInvest from "./wallet/DepositInvest";
import DepositWithdraw from "./wallet/DepositWithdraw";
import RewardsTransactions from "./portfolio/RewardsTransactions";
import CoinTable from "./wallet/CoinTable";
function DhyeyDemo() {
  return (
    <>
      <DepositInvest />
      <DepositWithdraw />
      <RewardsTransactions />
      <CoinTable />

    </>
  );
}

export default DhyeyDemo;
