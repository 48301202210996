import React from 'react'
import Profile from "../../assets/images/profile.svg";

function Overview() {
    return (
        <div className="rounded-2xl bg-white pb-2">
            <div className="py-4 px-6  border-b border-[#C4C4C4]">
                <p className="font-semibold text-2xl color-[#002C3A]">Available</p>
            </div>
            <div className="border-solid border border-[#C4C4C4] rounded-2xl mx-4 my-4 py-2 px-4">
                <div className="flex gap-2 py-1">
                    <img src={Profile} alt="Profile"/>
                    <p className="text-[#00C3FF] font-semibold text-lg">Account Value</p>
                </div>
                <div>
                    <p className="text-5xl font-black pb-2">$ 0.00</p>
                </div>
            </div>
            <div className="border-solid border border-[#C4C4C4] rounded-2xl my-4 mx-4">
                <div className="border-b border-[#C4C4C4] py-2 px-4">
                    <div className="flex gap-2 py-1">
                        <p className="text-[#959BC9] font-semibold text-lg">Invested</p>
                    </div>
                    <div className="">
                        <p className="text-[#002C3A] font-semibold text-3xl pb-2">$ 0.00</p>
                    </div>
                </div>
                <div className="py-2 px-4">
                    <div className="flex gap-2 py-1">
                        <p className="text-[#1D288A] font-semibold text-lg opacity-50">Earnings</p>
                    </div>
                    <div>
                        <p className="text-[#002C3A] font-semibold text-3xl pb-2">$ 0.00</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Overview
