import EmailVerify from "./auth/EmailVerify";
import EmailVerifySuccess from "./auth/EmailVerifySuccess";
import PasswordResetSuccess from "./auth/PasswordResetSuccess";

function Demo() {
  return (
    <>
      <EmailVerify />
      {/* <EmailVerifySuccess /> /}
      {/ <PasswordResetSuccess /> */}
    </>
  );
}

export default Demo;