import React from 'react'
import Layout from '../../components/layout/Layout'

function Fiat() {
    return (
        <Layout>
            Hello
        </Layout>
    )
}

export default Fiat
