import React from 'react'
import Layout from '../../components/layout/Layout'
import CoinTable from '../wallet/CoinTable'
import RewardsTransactions from './RewardsTransactions'
import Available from './Available'
import Invest from './Invest'
import Progress from './Progress'
import Levels from './Levels'
import Button from '../../components/styledComponents/Buttons/Button'

function Portfolio() {
    return (
        <Layout>
            <div className="flex gap-2 mx-10 max-h-full">
                <div className='w-2/3 max-h-full flex flex-col gap-3'>
                    <div className='bg-white px-6 py-2 rounded-2xl'>
                        <p className="font-semibold text-3xl py-4 color-[#002C3A]">Portfolio</p>
                        <div className="">
                            <CoinTable/>
                        </div>
                        <div>

                        </div>
                    </div>
                    <div className='bg-white rounded-2xl max-h-full'>
                        <RewardsTransactions/>
                    </div>
                </div>
                <div>

                </div>
                <div className="flex flex-col gap-3 w-1/3 max-h-full">
                   <div>
                        <Available/>
                   </div>
                   <div>
                        <Invest/>
                   </div>
                   <div>
                       <Progress/>
                   </div>
                   <div>
                       <Levels/>
                   </div>
                   <div className='py-2'>
                        <Button className="w-full"> % SWAP YLD</Button>
                   </div>
                </div>
            </div>
        </Layout>
    )
}

export default Portfolio
