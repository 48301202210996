import React, { useState } from "react";
import NavbarLogo from "../../assets/images/navbar_logo.svg";
import SettingLogo from "../../assets/images/settings_icon.svg";
import LanguageLogo from "../../assets/images/language_logo.svg";
import CheckButton from "../../components/styledComponents/Buttons/CheckButton";
import Verify from "../../assets/images/verify_setting.svg";

import Profile from "../../assets/images/profile_setting.svg";

import Security from "../../assets/images/security_setting.svg";
import Legal from "../../assets/images/legal_setting.svg";
import Support from "../../assets/images/help_setting.svg";
import Logout from "../../assets/images/logout_setting.svg";
import { NavLink, Link } from "react-router-dom";
function Header({ history }) {
  const [showOptions, setShowOptions] = useState(false);

  const handleClick = () => {
    setShowOptions(!showOptions);
  };

  window.onclick = function (event) {
    if (!event.target.matches(".dropbtn")) {
      setShowOptions(false);
    }
  };

  return (
    <div className="flex justify-between py-4 px-20 w-full max-h-full">
      <div className="flex gap-6 items-center font-medium text-lg">
        <img src={NavbarLogo} alt="navbar" />
        <NavLink
          exact

          to="/dashboard"

          className={({ isActive }) => `
                            text-lg text-center text-[#1D288A] ${
                              !isActive && "opacity-50"
                            } font-semibold px-2 py-2 ${isActive && ""}
                        `}
        >
          Dashboard
        </NavLink>
        <NavLink
          exact
          to="/portfolio"
          className={({ isActive }) => `
                            text-lg text-center text-[#1D288A] ${
                              !isActive && "opacity-50"
                            } font-semibold px-2 py-2 ${isActive && ""}
                        `}
        >
          Portfolio
        </NavLink>
        <NavLink
          exact
          to="/fiat"
          className={({ isActive }) => `
                            text-lg text-center text-[#1D288A] ${
                              !isActive && "opacity-50"
                            } font-semibold px-2 py-2 ${isActive && ""}
                        `}
        >
          Fiat
        </NavLink>
        <NavLink
          exact
          to="/swap"
          className={({ isActive }) => `
                            text-lg text-center text-[#1D288A] ${
                              !isActive && "opacity-50"
                            } font-semibold px-2 py-2 ${isActive && ""}
                        `}
        >
          Swap
        </NavLink>
      </div>

      <div className="flex max-h-full">
        <NavLink
          exact
          to="/wallet"
          className="pr-5 h-full flex justify-center items-center"
        >
          <CheckButton logo="wallet">Wallet</CheckButton>
        </NavLink>

        <div class="relative inline-block">
          <div className="h-full w-full">
            <button
              onClick={handleClick}
              type="button"
              class="dropbtn bg-[#E5F9FF] rounded-2xl px-2 py-1 flex justify-center items-center max-w-full h-full focus:bg-[#34CFFF] focus:text-white"
              id="menu-button"
              aria-expanded="true"
              aria-haspopup="true"
            >
              <img
                src={SettingLogo}
                alt="Setting"
                className="dropbtn h-[80%] w-[80%]"
              />
            </button>
          </div>
          {showOptions && (
            <div
              class="origin-top-right absolute right-0 mt-2 w-64 rounded-2xl bg-white border-[1px] border-[#34CFFF] shadow-[0px_5px_20px_#C0EFFF] z-10"
              role="menu"
              aria-orientation="vertical"
              aria-labelledby="menu-button"
              tabindex="-1"
            >
              <div className=" px-2 py-4 flex flex-col " role="none">
                <div className="flex text-[#1D288A] opacity-50 font-semibold text-lg py-2 content-center pl-2 items-center">
                   <Link
                    to="/"
                    class=""
                    role="menuitem"
                    tabindex="-1"
                    id="menu-item-0"
                  > <div className="w-full flex items-center">
                    <div className="">
                      <img src={Verify} alt="Verify" className=""/>
                    </div>
                    <div className="pl-2">
                      <p>Verify Your Identity</p>
                    </div></div>
                  </Link>
                </div>
                <div className="flex text-[#1D288A] opacity-50 font-semibold text-lg py-2 content-center pl-2  items-center">
                   <Link
                    to="/settings/profile/"
                    class=""
                    role="menuitem"
                    tabindex="-1"
                    id="menu-item-1"
                  > <div className="w-full flex items-center">
                    <div className="">
                      <img className=""
                        src={Profile}
                        alt="Profile"
                        
                      />
                    </div>
                    <div className="pl-2">
                      <p>Profile</p>
                    </div></div>
                  </Link>
                </div>
                <div className="flex text-[#1D288A] opacity-50 font-semibold text-lg py-2 content-center pl-2  items-center">
                   <Link
                    to="/settings/security/"
                    class=""
                    role="menuitem"
                    tabindex="-1"
                    id="menu-item-2"
                  > <div className="w-full flex items-center">
                    <div className="">
                      <img src={Security} alt="Security" className=""/>
                    </div>
                    <div className="pl-2">
                      <p>Security</p>
                    </div></div>
                  </Link>
                </div>
                <div className="flex text-[#1D288A] opacity-50 font-semibold text-lg py-2 content-center pl-2  items-center">
                   <Link
                    to="/settings/legal/"
                    class=""
                    role="menuitem"
                    tabindex="-1"
                    id="menu-item-3"
                  > <div className="w-full flex items-center">
                    <div className="">
                      <img src={Legal} alt="Legal" className=""/>
                    </div>
                    <div className="pl-2">
                      <p>Legal</p>
                    </div></div>
                  </Link>
                </div>
                <div className="flex text-[#1D288A] opacity-50 font-semibold text-lg py-2 content-center pl-2  items-center">
                   <Link
                    to="/settings/help-and-support/"
                    class=""
                    role="menuitem"
                    tabindex="-1"
                    id="menu-item-4"
                  > <div className="w-full flex items-center">
                    <div className="">
                      <img src={Support} alt="Support" className=""/>

                    </div>
                    <div className="pl-2">
                      <p>Help & Support</p>
                    </div></div>
                  </Link>
                </div>
                <div className="flex text-[#FF3169] font-semibold text-lg py-2 content-center pl-2  items-center">
                   <Link
                    to="/"
                    class=""
                    role="menuitem"
                    tabindex="-1"
                    id="menu-item-5"
                  > <div className="w-full flex items-center">
                    <div className="">
                      <img src={Logout} alt="Logout" className=""/>
                    </div>
                    <div className="pl-2">
                      <p>Logout</p>
                    </div></div>
                  </Link>
                </div>
              </div>
            </div>
          )}
        </div>

        <div className="pl-5 h-full flex justify-center items-center">
          <button className="bg-[#E5F9FF] h-full max-w-full rounded-2xl px-2 py-2 flex justify-center items-center">
            <img
              src={LanguageLogo}
              alt="Language"
              className="h-[90%] w-[90%]"
            />
          </button>
        </div>
      </div>
    </div>
  );
}


export default Header;

